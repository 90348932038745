import TYPES from "../types";

const initialState = {
    activecategory: null,
    activesubcategory: [],
};

export const ActiveCategoryReducer = (state = initialState, action: { type: any; payload: { data: any; }; }) => {
    switch (action.type) {
        case TYPES.ACTIVE_CATEGORY:
            return { ...state, activecategory: action.payload };
        case TYPES.ACTIVE_SUBCATEGORY:

            if (action.payload) {
                let activesubcategoryExist = state.activesubcategory.find((cat: any) => cat == action.payload);

                if (!activesubcategoryExist) {
                    return { ...state, activesubcategory: [...state.activesubcategory, action.payload] };
                } else return { ...state, activesubcategory: state.activesubcategory.filter((cat: any) => cat != action.payload) };
            } else return { ...state, activesubcategory: [] };

        default:
            return state;
    }
};