import React, { FC, useEffect, useState } from 'react'
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg'
import AnimatedInput from './AnimatedInput'
import AnimatedTelInput from './AnimatedTelInput'

const ContactForm: FC = () => {
  const [values, setValues] = useState({
    nameSurname: null,
    email: null,
    phoneNo: null,
    message: null

  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, name?: string) => {
    const value = e?.target?.value ?? e;
    const inputName = e?.target?.name ?? name;
    setValues((prevState) => ({ ...prevState, [inputName]: value }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // console.log(values)
  }


  useEffect(() => {
    // console.log('the form values are : ', values)
  },
    [values])


  return (
    <div className='w-full lg:w-[450px] border border-[#DDDDDD] rounded-[30px] p-[30px] bg-white lg:translate-y-[60px] translate-y-8' style={{ "height": "min-content" }}>
      <span className='text-2xl lg:text-[28px] font-bold md:font-semibold'>
      Formulaire de contact
      </span>
      <form className='w-full space-y-[30px] md:space-y-0 2xl:space-y-[30px] md:gap-4 2xl:gap-0 mt-6 grid grid-cols-1 md:grid-cols-3 xl:grid-cols-1' onSubmit={handleSubmit} >
        <AnimatedInput value={values.nameSurname} inputType='text' label='Nom, Prenom' name='nameSurname' onChange={(e: any) => handleChange(e)} />
        <AnimatedInput value={values.email} inputType='text' label='E-Mail' name='email' onChange={(e: any) => handleChange(e)} />
        {/* <AnimatedInput value={values.phoneNo} inputType='text' label='Phone Number' name='phoneNo' onChange={(e: any) => handleChange(e)}/> */}
        <AnimatedTelInput value={values.phoneNo} inputType='text' label='Phone' name='phoneNo' onChange={(e: any) => handleChange(e, 'phoneNo')} />
        <AnimatedInput value={values.message} inputType='textArea' label='Votre message' name='message' onChange={(e: any) => handleChange(e)} wrapperClassName='h-[150px] md:col-span-3 xl:col-span-1' />
        <button className='w-full bg-[#517eb3] flex justify-between items-center px-[30px] py-3 lg:py-[18px] text-white text-base lg:text-[22px] font-medium rounded-[10px]'>Envoyer<ArrowRight className='w-4 md:w-auto' /></button>
      </form>
    </div>
  )
}

export default ContactForm