import FAQ from 'components/FAQ/FAQ';
import React from 'react';

const FaqView = () => {
  return (
    <div>
        <FAQ />
    </div>
  )
};

export default FaqView;