import { productImgs } from "contains/fakeData";
import productVariantImg2 from "images/products/v2.jpg";
import productVariantImg3 from "images/products/v3.jpg";
import productVariantImg4 from "images/products/v4.jpg";
import productVariantImg5 from "images/products/v5.jpg";
import productVariantImg6 from "images/products/v6.jpg";
//
import productSport1 from "images/products/sport-1.png";
import productSport2 from "images/products/sport-2.png";
import productSport3 from "images/products/sport-3.png";
import productSport4 from "images/products/sport-4.png";
import productSport5 from "images/products/sport-5.png";
import productSport6 from "images/products/sport-6.png";
import productSport7 from "images/products/sport-7.png";
import productSport8 from "images/products/sport-8.png";

import product1 from "images/products/roulements.png";
import product2 from "images/products/moteurselectrique.png";
import product3 from "images/products/filtresahuile.png";
import product4 from "images/products/pompehydraulique.png";
import product5 from "images/products/courroiedetransmission.png";
import product6 from "images/products/capteursdepression.png";
import product7 from "images/products/vanneindustrielle.png";
import product8 from "images/products/systemedefreinages.png";
import product9 from "images/products/visetboulonsdehaute.png";
import product10 from "images/products/echangeursdechaleurs.png";

//

export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
  category: string;
  tags: string[];
  link: "/product-detail/";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
}

const DEMO_VARIANTS: ProductVariant[] = [
  {
    id: 1,
    name: "Black",
    thumbnail: productVariantImg6,
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "White",
    thumbnail: productVariantImg2,
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    thumbnail: productVariantImg3,
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    thumbnail: productVariantImg4,
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Natural",
    thumbnail: productVariantImg5,
    featuredImage: productImgs[4],
  },
];
const DEMO_VARIANT_COLORS: ProductVariant[] = [
  {
    id: 1,
    name: "Violet",
    color: "bg-violet-400",
    featuredImage: productImgs[0],
  },
  {
    id: 2,
    name: "Yellow",
    color: "bg-yellow-400",
    featuredImage: productImgs[1],
  },
  {
    id: 3,
    name: "Orange",
    color: "bg-orange-400",
    featuredImage: productImgs[2],
  },
  {
    id: 4,
    name: "Sky Blue",
    color: "bg-sky-400",
    featuredImage: productImgs[3],
  },
  {
    id: 5,
    name: "Green",
    color: "bg-green-400",
    featuredImage: productImgs[4],
  },
];

export const PRODUCTS: Product[] = [
  {
    id: 2,
    name: "Roulements",
    description: "Essentiels pour assurer le bon fonctionnement des moteurs et autres équipements rotatifs.",
    price: 680000,
    image: product1,
    category: "industrial",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 4,
    name: "Moteurs électriques",
    description: "Utilisés dans de nombreuses machines industrielles, notamment les pompes, ventilateurs et compresseurs.",
    price: 280000,
    image: product2,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    status: "Sold Out",
  },
  {
    id: 6,
    name: "Filtres à huile et à air ",
    description: "Utilisés dans les machines industrielles pour maintenir une bonne performance et prolonger la durée de vie des équipements.",
    price: 300000,
    image: product3,
    category: "vehicles",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/product-detail/",
  },
  {
    id: 7,
    name: "Pompes hydrauliques",
    description: "Nécessaires pour les systèmes de transmission d’énergie dans les installations industrielles",
    price:550000,
    image: product4,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 8,
    name: "Courroies de transmission",
    description: "Utilisées dans les systèmes de convoyeurs et machines pour transférer la puissance.All-natural soaps, customizable based on client requirements for scent and size.",
    price: 200000,
    image: product5,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },

  {
    id: 9,
    name: "Capteurs de pression",
    description: "Utilisés pour surveiller et contrôler la pression dans les équipements industriels.Popular West African staple made from fermented cassava, ideal for international markets",
    price: 300000,
    image: product6,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 10,
    name: "Vannes industrielles",
    description: "Employées pour le contrôle des fluides dans les processus industriels.",
    price: 250000,
    image: product7,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 11,
    name: "Systèmes de freinage",
    description: "Essentiels pour les grandes machines afin de garantir la sécurité et la performance.",
    price: 500000,
    image: product8,
    category: "vehicles",
    tags: ["Freinage", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 12,
    name: "Vis et boulons de haute résistance",
    description: "Utilisés pour fixer divers composants dans les installations industrielles.",
    price: 700000,
    image: product9,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 13,
    name: "Échangeurs de chaleur",
    description: "Importants pour réguler les températures dans divers systèmes industriels.",
    price: 700000,
    image: product10,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
];

export const SPORT_PRODUCTS: Product[] = [
  {
    id: 2,
    name: "Roulements",
    description: "Essentiels pour assurer le bon fonctionnement des moteurs et autres équipements rotatifs.",
    price: 680000,
    image: product1,
    category: "industrial",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
  },
  {
    id: 4,
    name: "Moteurs électriques",
    description: "Utilisés dans de nombreuses machines industrielles, notamment les pompes, ventilateurs et compresseurs.",
    price: 280000,
    image: product2,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    status: "Sold Out",
  },
  {
    id: 6,
    name: "Filtres à huile et à air ",
    description: "Utilisés dans les machines industrielles pour maintenir une bonne performance et prolonger la durée de vie des équipements.",
    price: 300000,
    image: product3,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variantType: "color",
    variants: DEMO_VARIANT_COLORS,
    link: "/product-detail/",
  },
  {
    id: 7,
    name: "Pompes hydrauliques",
    description: "Nécessaires pour les systèmes de transmission d’énergie dans les installations industrielles",
    price:550000,
    image: product4,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 8,
    name: "Courroies de transmission",
    description: "Utilisées dans les systèmes de convoyeurs et machines pour transférer la puissance.All-natural soaps, customizable based on client requirements for scent and size.",
    price: 200000,
    image: product5,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },

  {
    id: 9,
    name: "Capteurs de pression",
    description: "Utilisés pour surveiller et contrôler la pression dans les équipements industriels.Popular West African staple made from fermented cassava, ideal for international markets",
    price: 300000,
    image: product6,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 10,
    name: "Vannes industrielles",
    description: "Employées pour le contrôle des fluides dans les processus industriels.",
    price: 250000,
    image: product7,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 11,
    name: "Systèmes de freinage",
    description: "Essentiels pour les grandes machines afin de garantir la sécurité et la performance.",
    price: 500000,
    image: product8,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 12,
    name: "Vis et boulons de haute résistance",
    description: "Utilisés pour fixer divers composants dans les installations industrielles.",
    price: 700000,
    image: product9,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
  {
    id: 13,
    name: "Échangeurs de chaleur",
    description: "Importants pour réguler les températures dans divers systèmes industriels.",
    price: 700000,
    image: product10,
    category: "industrial",
    tags: ["tag1", "tag2"],
    variants: DEMO_VARIANTS,
    variantType: "image",
    link: "/product-detail/",
    status: "New in",
  },
];
