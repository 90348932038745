import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

export interface ServicesProps {
  service?: any;
};

const Service: FC<ServicesProps> = ({ service }) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className='flex flex-col lg:flex-row w-full' >
      <div className='w-full lg:h-[620px]'>
        <img src={service.image}  className='rounded-[20px]'/>
      </div>
      <div className={`flex flex-col lg:space-x-[100px] lg:-ml-[200px] lg:px-[51px] w-full ${service.cardHeight} bg-[#FFFFFF] rounded-bl-[30px]`}>
        <div className='mt-[30px] lg:mt-[51px] mb-4 lg:mb-0'>
          <img src={service.icon} className='w-[30px] lg:w-auto' />
        </div>
        <div className='flex flex-col space-y-5 lg:space-y-[10px] lg:-mt-[51px]'>
          <div className='font-poppins font-bold lg:font-semibold text-2xl lg:text-[28px] text-[#0D2C3B] leading-[44px] tracking-[-0.07em]'>
            {service.name}
          </div>
          <div className={`font-poppins font-normal text-lg text-[18px] text-[#000000] tracking-[-0.03em] leading-[26px] ${!readMore && "line-clamp-6"}`}>
            { service.description }
            {/* <div dangerouslySetInnerHTML={{ __html: service.content.text }} /> */}
          </div>
          {/* <Link to={`/services/${service.id}`} >
            <button className='font-medium text-[#0D2C3B] self-start lg:inline-block underline cursor-pointer' onClick={() => setReadMore(!readMore)}>
              {"Plus d'information"}
            </button>
          </Link> */}
          <div className='font-poppins font-semibold text-sm lg:text-[16px] text-[#0D2C3B] opacity-50 leading-[24px]'>
            {service.subdescription}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Service