import React, { useState } from 'react'
// import Label from '../../containers/Label/Label';
// import CInput from '../../containers/Input/CInput';
// // import { useForm, useValidate } from '../../hooks';
// import StyleButton from '../../containers/Button/StyleButton';
// @ts-ignore
import { useForm } from "react-hook-form";
// @ts-ignore
import emailjs from '@emailjs/browser';
import CInput from 'shared/Input/CInput';
import AnimatedTelInput from 'shared/Input/AnimatedTelInput';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg'

const RequestQuoteForm = () => {
    // const {
    //     values,
    //     errors,
    //     handleChange,
    //     handleSubmit
    // } = useForm(_handleRequestQuote, useValidate, 'request_quote');
    const [submissionState, setSubmissionState] = useState<any>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const submitHandler = (data: any, e: any) => {
        const form = e?.target;
        e?.preventDefault();

        console.log({ form })
        e?.persist();
        e?.preventDefault();

        emailjs
            .sendForm(
                'service_wre4w5k', // service id
                'template_hltg6ke', // template id
                form,
                'OVH4nrhd5EuYR3iWS' // Public Key
            )
            .then(
                (result: any) => {
                    // console.log({ result });
                    // setTimeout(() => {
                    //     setIsSubmitting(false);
                    setSubmissionState(result);
                    //     // setStateMessage(null);
                    // }, 3000); // hide message after 5 seconds

                },
                (error: any) => {
                    //   setStateMessage('Something went wrong, please try again later');
                    //   setIsSubmitting(false);
                    console.log({ error });
                    setTimeout(() => {
                        // setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                }
            );

        // Clears the form after sending the email
        e?.target.reset();
    };

    return (
        <form onSubmit={handleSubmit(submitHandler)}>
            <div className='w-full flex flex-col space-y-2'>
                <div className='flex flex-col md:flex-row md:space-x-2'>
                    <div className='md:w-1/2'>
                        <CInput
                            type={'text'}
                            // error={errors && errors['username']}
                            name={'reference'}
                            placeholder={"Référence de la pièce"}
                            // onChange={(event: any) => { handleChange(event) }}
                            className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                            sizeClass='h-[50px] w-full'
                            bgColor='bg-gray-200'
                        />
                    </div>
                    <div className='md:w-1/2'>
                        <CInput
                            type={'text'}
                            // error={errors && errors['username']}
                            name={'marque'}
                            placeholder={"Marque de la pièce"}
                            // onChange={(event: any) => { handleChange(event) }}
                            className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                            sizeClass='h-[50px] w-full'
                            bgColor='bg-gray-200'
                        />
                    </div>
                </div>
                <div className=''>
                    <CInput
                        type={'text'}
                        // error={errors && errors['username']}
                        name={'namesurname'}
                        placeholder={"Nom Prenom"}
                        // onChange={(event: any) => { handleChange(event) }}
                        className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                        sizeClass='h-[50px] w-full'
                        bgColor='bg-gray-200'
                    />
                </div>
                <div className=''>
                    <CInput
                        type={'email'}
                        error={errors && errors['email']}
                        name={'email'}
                        placeholder={"Email"}
                        // onChange={(event: any) => { handleChange(event) }}
                        className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                        sizeClass='h-[50px] w-full'
                        bgColor='bg-gray-200'
                    />
                </div>
                <div className=''>
                    <AnimatedTelInput
                        // value={values.phoneNo} 
                        inputType='text'
                        label='Numero de telephone'
                        name='phoneNo'
                        sizeH="h-[48px]"
                        wrapperClassName='rounded-[24px]'
                    // onChange={(e: any) => handleChange(e, 'phoneNo')} 
                    />
                </div>
                <div>
                    <CInput
                        type={'text'}
                        // error={errors && errors['email']}
                        name={'company'}
                        placeholder={"Entreprise"}
                        // onChange={(event: any) => { handleChange(event) }}
                        className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                        sizeClass='h-[50px] w-full'
                        bgColor='bg-gray-200'
                    />
                </div>
                <div>
                    <CInput
                        type={'textarea'}
                        // error={errors && errors['email']}
                        name={'otherInformations'}
                        placeholder={"Autres informations (pièce neuve/occasion, niveau d'urgence, taxi colis, ...)"}
                        // onChange={(event: any) => { handleChange(event) }}
                        className='text-black placeholder-gray-600 w-full py-2.5 mt-2 text-base transition duration-500 ease-in-out transform border-transparent rounded-lg bg-[#D9D9D9] focus:border-blueGray-500 focus:bg-white dark:focus:bg-gray-800 focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2 ring-gray-400'
                        sizeClass='h-[50px] w-full'
                        bgColor='bg-gray-200'
                    />
                </div>
            </div>

            <div>
                {submissionState?.status == 200 && <div className='flex justify-end text-green-700'> Merci d’avoir soumis la requête. </div>}
                <button className='w-full bg-[#517eb3] flex justify-between items-center px-[30px] py-3 lg:py-[18px] text-white text-base lg:text-[22px] font-medium rounded-[10px]'>Send<ArrowRight className='w-4 md:w-auto' /></button>
                {/* <StyleButton
                    type={'submit'}
                    content={'Envoyer'}
                    sizeWH="w-[300px] h-[35px] md:w-[170px] md:h-[48px]"
                    roundedStyle="rounded-[24px]"
                    bgColor="bg-gradient-to-bl from-cyan-700/40 to-violet-600/40"
                    handleClick={handleSubmit}
                /> */}
            </div>

        </form>
    )
};

export default RequestQuoteForm;