import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import lsd from '../../images/lsd.png';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Liens",
    menus: [
      { href: "/", label: "Accueil" },
      { href: "/products", label: "Pièces de rechange" },
      // { href: "/", label: "Services" },
      { href: "/aboutus", label: "A propos" },
      { href: "/contactus", label: "Contact" },
      { href: "/tender-call", label: "Les appels d'offres" },
      { href: "/faq", label: "FAQ" },
    ],
  },
  {
    id: "1",
    title: "Catégories",
    menus: [
      { href: "/products", label: "Pièces mécaniques" },
      { href: "/products", label: "Électronique et composants" },
      { href: "/products", label: "Pièces hydrauliques" },
      { href: "/products", label: "Accessoires et consommables" },
      { href: "/products", label: "Équipements de sécurité" },
      { href: "/products", label: "Outils et machines" },
      { href: "/products", label: "Systèmes de contrôle et d’automatisation" },
    ],
  },
  {
    id: "2",
    title: "Formations",
    menus: [
      { href: "/", label: "Gestion des pièces de rechange et des stocks" },
      { href: "/", label: "Maintenance industrielle" },
      { href: "/", label: "Automatisation et contrôle industriel" },
      { href: "/", label: "Programmation des automates (PLC)" },
      { href: "/", label: "Sécurité industrielle" },
      { href: "/", label: "Formation en achats industriels" },
    ],
  },
  {
    id: "4",
    title: "Ressources",
    menus: [
      { href: "/", label: "Articles sur l’industrie" },
      { href: "/", label: "Guides pratiques" },
      { href: "/", label: "Liste des partenaires" },
      { href: "/", label: "Devenir partenaire" },
      { href: "/", label: "Devenir exposant" },
      { href: "https://factoconn.s3.us-west-1.amazonaws.com/CATALOGUE+PIECES+OBSOLETE.pdf", targetBlank: true, label: "Catalogue" },
      { href: "/tender-call", label: "Les appels d'offres" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                // target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
          </div>
          <span onClick={() => window.open("https://www.loven-soft.com", "_blank")} className='flex md:flex-row flex-col  item-center justify-center md:-ml-16 mt-4 md:pb-4'>
            <span>Réalisé par</span>
            <a href="https://www.loven-soft.com" className='cursor-pointer' target='_blank'>
              <img onClick={() => window.open("https://www.loven-soft.com", "_blank")} className="w-16 -mt-4 cursor-pointer" src={lsd} />
            </a>
          </span>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
