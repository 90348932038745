import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import backgroundLineSvg from "images/Moon.svg";
import imageRightPng2 from "images/hero-2-right-1.png";
// import banner from "images/banner.png";
import banner from "images/facto.png";

import { createBrowserHistory } from "history";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {

  const history : any = createBrowserHistory();

  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="relative pt-8 lg:pt-0 lg:absolute z-10 inset-x-0 top-[10%] sm:top-[20%]  container">
        <div className="flex flex-col items-start max-w-lg xl:max-w-2xl space-y-5 xl:space-y-8 ">
          <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
            {/* In this season, find the best  */}
          </span>
          <h2 className="font-bold bg-gradient-to-l from-[#547cb4] to-cyan-900  text-transparent bg-clip-text text-black text-3xl sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl !leading-[115%] ">
           {/* Factoconn : La connexion essentielle pour l’industrie  */}
           Achetez, vendez ou valorisez vos pièces de rechanges
          </h2>
          <div className="sm:pt-4 flex flex-row space-x-2">
            <ButtonPrimary
              sizeClass="px-6 py-3 lg:px-8 lg:py-4"
              fontSize="text-sm sm:text-base lg:text-lg font-medium"
              onClick={() => window.location.href = '/request-need'}
            >
              Exprimer un besoin
            </ButtonPrimary>
            <ButtonSecondary
              sizeClass="px-6 py-3 lg:px-8 lg:py-4"
              fontSize="text-sm sm:text-base lg:text-lg font-medium"
              onClick={() => window.location.href = '/tender-call'}
            >
              Nos Appels d'offre
            </ButtonSecondary>
          </div>
        </div>
      </div>

      <div className="relative z-[1] lg:aspect-w-16 lg:aspect-h-8 2xl:aspect-h-7">
        <div className=" ">
          <div className="mt-5 lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl ml-auto">
            <img
              className="w-full sm:h-full object-right-bottom rounded-[20px]"
              src={banner}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* BG : bg-[#cbddf2] */}
      <div className="absolute inset-0 rounded-2xl overflow-hidden z-0">
        <img
          className="absolute w-full h-full object-contain"
          src={backgroundLineSvg}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;
