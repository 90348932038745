import TYPES from "../types";

const getInitialLang = () => {
    const langFromURL = window.location.pathname.split('/')[1] 
    
   const initialLang = 'fr'

   return initialLang
};

const initialState = {
    lang: getInitialLang()
};

// signin reducer
export const LanguagesReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case TYPES.LANGUAGE:
            return {...state, lang: action.payload};
        default:
            return state;
    }
};