import AlgoliaSearch from 'algoliasearch';
import { PRODUCTS } from 'data/data';
import moment from 'moment';

let ALGOLIA_APPLICATION_ID = "PIV1IKAUU0";
let ALGOLIA_PRIVATE_ID = "07de43c8d99675c6097a8d9b21da778e";

// console.log({ALGOLIA_PRIVATE_ID});
// console.log({ALGOLIA_PRIVATE_ID});

const client = AlgoliaSearch(ALGOLIA_APPLICATION_ID, ALGOLIA_PRIVATE_ID);
const index = client.initIndex('factory_parts');

// console.log({index});
const algoliaReset = async () => {
  console.log('ALGOLIA: reset index');
  index?.clearObjects((error, content) => { console.log('ALGOLIA: reset index result', error, content); });
};

let algoliaUpdateInProgress = false;

const algoliaUpdate = (force) => {
  if (algoliaUpdateInProgress) return console.log('ALGOLIA: Update already in progress');
  algoliaUpdateInProgress = true;

  const before = moment();

  let selector = {};
  let array = [];

  if (force) {
    algoliaReset();
  } else {
    selector.updateAlgolia = true;
  }

  PRODUCTS.find().forEach(p => {
    // if(p.pole == 'Activity Guide' || p.pole == 'RESIDENCE'){
        array.push({
            objectID: p.id,
            title: p.name,
            category: p.category,
            description: p.description,
            // partsDescription: p.partsDescription,
            // references: p.references,
            // partMaterial: p.partMaterial
        });
    // }
  });

  // Poles.update({ updateAlgolia: true }, { $unset: { updateAlgolia: '' } }, { multi: true });

  
  if(array.length){
    // console.log({array});

    try {
        index.saveObjects(array, (err, res) => {
            if (err) console.error(Date(), 'ERROR:', err, res);
            else console.log('ALGOLIA: Updated ' + array.length + ' packages', moment().diff(before) / 1000, 'seconds');
            algoliaUpdateInProgress = false;
          });
    } catch (error) {
        console.log({error});
    }

  }
  algoliaUpdateInProgress = false;
};


// Meteor.methods({
//   algoliaUpdate() {
//     // if (!isAdmin(this.userId)) return;
//     algoliaUpdate();
//   },
// });

export {
    algoliaReset,
    algoliaUpdate
};