import TYPES from "../types";

const initialState = {
    addedtocart: [],
};

// signin reducer
export const addToCartReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case TYPES.ADD_T0_CART:
            // return {...state, searchaccomodationhotels: [...state.searchaccomodationhotels, action.payload.data[0]] }
            let itemExist = state.addedtocart.find((item: any) => item.id == action.payload.id);
            // console.log({hotelExist});
            if (!itemExist) {
                return { ...state, addedtocart: [...state.addedtocart, action.payload] };
            } else return { ...state, addedtocart: [...state.addedtocart] };
        case TYPES.REMOVE_FROM_CART:
             return { ...state, addedtocart: state.addedtocart.filter(((item: any) => item?.id !== action?.payload.id)) };
        default:
            return state;
    }
};