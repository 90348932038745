import TYPES from "store/types";

export const activeCategory = (cat: any) => {
    return async (dispatch: (arg0: { type: any; payload: any; }) => void) => {
        dispatch({
            type: TYPES.ACTIVE_CATEGORY,
            payload: cat
        });
    };
};

export const activeSubCategory = (cat: any) => {
    return async (dispatch: (arg0: { type: any; payload: any; }) => void) => {
        dispatch({
            type: TYPES.ACTIVE_SUBCATEGORY,
            payload: cat
        });
    };
};