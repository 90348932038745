import React, { FC, InputHTMLAttributes, useRef } from 'react';


export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
  label?: string;
  placeholder?: string;
  onChange?: any;
  name?: string;
  disabled?: boolean;
  error?: any;
  bgColor?: string;
};


const CInput: FC<InputProps> = React.forwardRef((
  {
    className = "block w-full dark:bg-[transparent] !ring-0",
    sizeClass = "h-13 py-3 ",
    fontClass = "text-sm font-normal",
    rounded = "rounded-xl",
    label = "",
    placeholder = "",
    children,
    type = "text",
    onChange,
    name = "",
    defaultValue,
    disabled = false,
    error,
    bgColor = ""
  }
) => {
  let ref: any = useRef<any>(null);

  const handleChange = (event: any) => {
    onChange && onChange(event);
  };

  return (
    <div className="relative mb-3" data-te-input-wrapper-init>
      {
        error &&
        <span className='absolute right-[5px] bg-red-600 rounded-lg text-white text-sm mt-4 p-2 z-10'>
          {error}
        </span>
      }
      <label
        htmlFor="inputlabel"
        className='translate-y-4 lg:translate-y-[27px] text-[16px] text-[#0D2C3B] text-opacity-50 z-[1]'
      >{placeholder}
      </label>
      {
        type == 'textarea' ?
          <textarea
            id={name}
            // value={value ?? inputValue}
            // onChange={(event) => onInputChange(event)}
            // onFocus={() => onFocus()}
            // onBlur={() => onBlur()}
            name={name}
            className={`${rounded} ${className} ${fontClass} ${bgColor} bg-gray-300 border-none w-full cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[2]  px-3 py-[0.32rem] leading-[1.6] outline-none bg-gray-200 transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0`}
            // className={`border-none bg-gray-600 w-full h-full rounded-[24px] cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[2] resize-none`}
            cols={30}
            rows={4}

          /> :
          <input
            id={name}
            type={type}
            name={name}
            defaultValue={defaultValue && defaultValue}
            placeholder={placeholder}
            onChange={event => handleChange(event)}
            className={`${rounded} ${className} ${fontClass} ${sizeClass} border-none w-full cursor-pointer transition ease-out bg-[#FFFFFF] focus:ring-0 relative z-[2]  px-3 py-[0.32rem] leading-[1.6] outline-none bg-gray-200 transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0`}
            // className={`${rounded} ${fontClass} ${sizeClass} ${className} ${bgColor} peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none bg-gray-200 transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0`}
            disabled={disabled}
            style={{ backgroundColor: bgColor ? '#D9D9D9' : '' }}
          />
      }
    </div>
  )
});

export default CInput;