import React, { useEffect } from 'react';
// import Adresses from '../../components/Adresses/Adresses';
// import FAQ from '../../components/FAQ/FAQ';
import PageTitleBar from './PageTitleBar';
import ContactInfoCards from './ContactInfoCards';
import ContactForm from './ContactForm';
import RequestQuoteForm from './RequestQuoteForm';
// import Map from '../../components/Map/Map';
// import ContactForm2 from '../../components/ContactForm/ContactForm2';
// import Faq2 from '../../components/FAQ/Faq2';

const Contact = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  return (
    <div className='container'>
      <PageTitleBar />
      <ContactInfoCards />
      <div className='container grid grid-cols-1 xl:grid-cols-3 gap-x-[30px] lg:mt-4 my-[30px] lg:px-9 2xl:px-0'>

        <div className={`${'col-span-2'} map overflow-hidden w-full h-full mt-20 xl:h-[626px]  lg:px-0`}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15889.25114468882!2d-3.9608023!3d5.3691764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1ebd2dac213d3%3A0xb3ed16e6b6176ac9!2sLOVEN%20SOFTWARE%20DEVELOPMENT%20-%20LSD!5e0!3m2!1sen!2sci!4v1728721464670!5m2!1sen!2sci" width="800" height="550" loading="lazy"></iframe>
        </div>

        <div className=' lg:px-0 w-full col-span-2 xl:col-span-1 mt-[30px] lg:mt-4 2xl:mt-0'>
          <ContactForm />
        </div>

        
      </div>
      {/* <div className='flex mx-auto items-center justify-center p-5'>
      <RequestQuoteForm />
      </div> */}
      
    </div>
  )
};

export default Contact;