const ACTIVE_SIDEBAR_ITEM: string = 'ACTIVE_SIDEBAR_ITEM';
const LANGUAGE: string = 'LANGUAGE';
const MACHINERY_PARTS_DATA: string = 'MACHINERY_PARTS_DATA';
const MACHINERY_PARTS_CAT: string = 'MACHINERY_PARTS_CAT';
const ADD_T0_CART: string = 'ADD_T0_CART';
const REMOVE_FROM_CART: string = 'REMOVE_FROM_CART';
const ACTIVE_CATEGORY: string = 'ACTIVE_CATEGORY';
const ACTIVE_SUBCATEGORY: string = 'ACTIVE_SUBCATEGORY';

const TYPES: any= {
    ACTIVE_SIDEBAR_ITEM,
    LANGUAGE,
    MACHINERY_PARTS_DATA,
    MACHINERY_PARTS_CAT,
    ADD_T0_CART,
    REMOVE_FROM_CART,
    ACTIVE_CATEGORY,
    ACTIVE_SUBCATEGORY
};

export default TYPES;