import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Liens",
    children: [
      { id: ncNanoId(), href: "/", name: "Accueil" },
      { id: ncNanoId(), href: "/products", name: "Pièces de rechange" },
      // { id: ncNanoId(), href: "/", name: "Services" },
      { id: ncNanoId(), href: "/", name: "A propos", isNew: false },
      { id: ncNanoId(), href: "/contactus", name: "Contact" },
      { id: ncNanoId(), href: "/tender-call", name: "Les appels d'offres" },
      { id: ncNanoId(), href: "/faq", name: "FAQ" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Catégories",
    children: [
      { id: ncNanoId(), href: "/products", name: "Pièces mécaniques" },
      { id: ncNanoId(), href: "/products", name: "Électronique et composants" },
      { id: ncNanoId(), href: "/products", name: "Pièces hydrauliques" },
      { id: ncNanoId(), href: "/products", name: "Accessoires et consommables" },
      { id: ncNanoId(), href: "/products", name: "Équipements de sécurité" },
      { id: ncNanoId(), href: "/products", name: "Outils et machines" },
      { id: ncNanoId(), href: "/products", name: "Systèmes de contrôle et d’automatisation" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Formations",
    children: [
      { id: ncNanoId(), href: "/", name: "Gestion des pièces de rechange et des stocks" },
      { id: ncNanoId(), href: "/", name: "Maintenance industrielle" },
      { id: ncNanoId(), href: "/", name: "Automatisation et contrôle industriel" },
      { id: ncNanoId(), href: "/", name: "Programmation des automates (PLC)" },
      { id: ncNanoId(), href: "/", name: "Sécurité industrielle" },
      { id: ncNanoId(), href: "/", name: "Formation en achats industriels" },
    ],
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Ressources",
    children: [
      { id: ncNanoId(), href: "/", name: "Articles sur l’industrie" },
      { id: ncNanoId(), href: "/", name: "Guides pratiques" },
      { id: ncNanoId(), href: "/", name: "Liste des partenaires" },
      { id: ncNanoId(), href: "/", name: "Devenir partenaire" },
      { id: ncNanoId(), href: "/", name: "Devenir exposant" },
      { id: ncNanoId(), href: "https://factoconn.s3.us-west-1.amazonaws.com/CATALOGUE+PIECES+OBSOLETE.pdf", targetBlank: true, name: "Catalogue" },
      // { id: ncNanoId(), href: "/tender-call", name: "Les appels d'offres" },
    ],
  },
];

const OTHER_PAGE_CHILD: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home Demo 1",
  },
  {
    id: ncNanoId(),
    href: "/home2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/page-collection",
    name: "Category Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/page-collection",
        name: "Category page 1",
      },
      {
        id: ncNanoId(),
        href: "/page-collection-2",
        name: "Category page 2",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/product-detail",
    name: "Product Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/product-detail",
        name: "Product detail 1",
      },
      {
        id: ncNanoId(),
        href: "/product-detail-2",
        name: "Product detail 2",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/cart",
    name: "Cart Page",
  },
  {
    id: ncNanoId(),
    href: "/checkout",
    name: "Checkout Page",
  },
  {
    id: ncNanoId(),
    href: "/page-search",
    name: "Search Page",
  },
  {
    id: ncNanoId(),
    href: "/account",
    name: "Account Page",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/about",
        name: "About",
      },
      {
        id: ncNanoId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: ncNanoId(),
        href: "/login",
        name: "Login",
      },
      {
        id: ncNanoId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: ncNanoId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/blog",
        name: "Blog Page",
      },
      {
        id: ncNanoId(),
        href: "/blog-single",
        name: "Blog Single",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: any[] = [
  { id: ncNanoId(), href: "/", name: "Accueil" },
  { id: ncNanoId(), href: "/products", name: "Pièces de rechange" },
  // { id: ncNanoId(), href: "/", name: "Services" },
  { id: ncNanoId(), href: "/aboutus", name: "A propos" },
  { id: ncNanoId(), href: "/contactus", name: "Contact" },
  { id: ncNanoId(), href: "/faq", name: "FAQ" },
  // {
  //   id: ncNanoId(),
  //   href: "/",
  //   name: "About us",
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Templates",
  //   type: "megaMenu",
  //   children: MEGAMENU_TEMPLATES,
  // },
  // {
  //   id: ncNanoId(),
  //   href: "/page-search",
  //   name: "Explore",
  //   type: "dropdown",
  //   children: OTHER_PAGE_CHILD,
  // },
];
