import React, { FC, useEffect } from 'react'
import Service from './Service';
import ServiceTranslate from './ServiceTranslate';
import { ServicesData } from './servicesData';

interface ServicesProps {
    service: any;
};

const Services = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
    }, []);

    return (
        <div className='container'>
            <div className='py-[50px] space-y-[30px] lg:space-y-[50px]'>
                {
                    ServicesData.map((service: any, idx: number) => (
                        (
                            idx % 2 == 0 ?
                                <Service service={service} /> :
                                <ServiceTranslate service={service} />
                        )
                    ))
                }
            </div>
        </div>
    )
};

export default Services;