import TYPES from "../types";

// machinery parts data action
export const addToCart = (add_to_cart: any) => {
    return async (dispatch: (arg0: { type: any; payload: any; }) => void) => {
        dispatch({
            type: TYPES.ADD_T0_CART,
            payload: add_to_cart
        });
    };
};

// remove from cart
export const removeFromCart = (remove_from_cart: any) => {
    return async (dispatch: (arg0: { type: any; payload: any; }) => void) => {
        dispatch({
            type: TYPES.REMOVE_FROM_CART,
            payload: remove_from_cart
        });
    };
};