import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from 'connected-react-router';
// import { createBrowserHistory } from 'history';

// import { configureStore, MiddlewareArray  } from '@reduxjs/toolkit';
// import additionalMiddleware from 'additional-middleware';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from "redux-thunk";
import reducers from "./reducers";
import history from "./history";
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from '@redux-devtools/extension';

// const history =  createBrowserHistory();

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const thunkMiddleware = thunk;
const routersMiddleware = routerMiddleware(history);
const middlewares = [thunkMiddleware, routersMiddleware];


const composeEnhancers = composeWithDevTools({ trace: true });
// const store = createStore(connectRouter(history)(persistedReducer), {}, composeEnhancers(applyMiddleware(...middlewares)));
const store = createStore((persistedReducer), {}, composeEnhancers(applyMiddleware(...middlewares)));

export let persistor = persistStore(store);

export default store;
