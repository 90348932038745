import Heading from 'components/Heading/Heading';
import RequestQuoteForm from 'containers/Contactus/RequestQuoteForm';
import React from 'react'

const RequestNeed = () => {
  return (
    <div className='container min-h-screen'>
      <Heading
        className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
        fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
        isCenter
        desc=""
      >
        Exprimer un besoin
      </Heading>
      <div className='flex items-center justify-center'>
        <RequestQuoteForm />
      </div>
    </div>
  )
}

export default RequestNeed;