import React from 'react'

const PageTitleBar = () => {
  return (
    <div className='w-full pt-[69px] pb-[117px] bg-[#cbddf2] dark:bg-[#222222] bg-opacity-10 flex justify-center '>
      <h1 className='text-[28px] lg:text-[40px] font-black lg:leading-[48px] text-center dark:text-white dark:font-semibold leading-[30px] tracking-[-0.07em]'>
      Contactez-nous pour des informations détaillées
        <br />
      </h1>
    </div>
  )
}

export default PageTitleBar