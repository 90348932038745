import React, { useEffect } from 'react';
// import shape from '../../assets/images/shape.png';
import Disclosure from './Disclosure';
// import { _FAQS_DATA, _FAQS_DATA_SUPPLIER } from '../../data/data';

const FAQ = () => {

    useEffect(() => {
        localStorage.clear();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

    }, []);

    return (
        <div className=''>
            <div className='relative mb-10'>
                <div className='absolute mt-72 ml-4 -z-40'>
                    {/* <img src={shape} /> */}
                </div>
                <Disclosure
                    items={_FAQS_DATA}
                    heading={"Questions Fréquemment Posées"}
                    customContainer="container"
                />
            </div>
        </div>
    )
}

export default FAQ;


export const _FAQS_DATA = [
    {
        "id": "1594744",
        "type": "detail_card",
        "field_title": "Qu’est-ce que Factoconn ?",
        "field_body": `
          Factoconn est une plateforme qui met en relation des usines, des fournisseurs et des particuliers dans le secteur industriel. Nous offrons une large gamme de pièces de rechange, d’équipements et de services pour optimiser les chaînes d’approvisionnement et résoudre les défis opérationnels.`
    },
    {
        "id": "1594745",
        "type": "detail_card",
        "field_title": "Quels produits puis-je trouver sur Factoconn ?",
        "field_body": `Factoconn propose des produits industriels tels que des moteurs électriques, roulements, pompes hydrauliques, filtres, courroies de transmission, capteurs de pression, vannes et systèmes de freinage, essentiels pour la maintenance et l’optimisation des installations industrielles.`
    },
    {
        "id": "1594746",
        "type": "detail_card",
        "field_title": "Comment puis-je rechercher des pièces de rechange sur Factoconn ?",
        "field_body": `Vous pouvez rechercher des pièces de rechange en utilisant notre barre de recherche avancée ou en naviguant dans les différentes catégories de produits. La plateforme est conçue pour une navigation facile et rapide.`
    },
    {
        "id": "1594747",
        "type": "detail_card",
        "field_title": "Puis-je demander des pièces rares ou obsolètes sur Factoconn ?",
        "field_body": `Oui, Factoconn est spécialisé dans la recherche de pièces industrielles rares ou obsolètes. Il suffit de soumettre une demande, et nous vous mettrons en relation avec des fournisseurs capables de fournir les pièces nécessaires. `
    },
    {
        "id": "1594748",
        "type": "detail_card",
        "field_title": "Comment passer une commande ?",
        "field_body": `Vous pouvez passer une commande en sélectionnant les produits souhaités, les ajoutant à votre panier, puis en suivant le processus de commande. Pour les commandes volumineuses ou spécifiques, vous pouvez également nous contacter directement.`
    },
    {
        "id": "1594973",
        "type": "detail_card",
        "field_title": "Quels services Factoconn propose-t-il en plus des produits ?",
        "field_body": `Factoconn offre des services supplémentaires tels que la maintenance des équipements, des formations industrielles, et des consultations pour aider les entreprises à optimiser leurs opérations et réduire les temps d’arrêt.`
    }
];