import React from 'react'
import { ReactComponent as PhoneIcon } from '../../images/icons/PhoneIcon.svg'
import { ReactComponent as LocationIcon } from '../../images/icons/LocationIcon.svg'
import { ReactComponent as MailIcon } from '../../images/icons/MailIcon.svg'
import ContactInfoCard from './ContactInfoCard'


const ContactInfoCards = () => {
  return (
    <div className='px-5 lg:px-9 2xl:px-0 grid lg:grid-cols-3 gap-y-5 gap-x-4 xl:gap-x-[30px] container -mt-[50px] '>
        {
            contactInfoData.map(item => {
                return (
                    <ContactInfoCard {...item} />
                )
            })
        }
    </div>
  )
}


const contactInfoData = [
    {
        title: 'Phone',
        subtitle: '+225 05 74 02 33 51',
        Icon: PhoneIcon,
        id: '1'
    },
    {
        title: 'E-mail',
        subtitle: 'contact@factoconn.com',
        Icon: MailIcon,
        id: '2'
    },
    {
        title: 'Location',
        subtitle: 'Bonoumin, Cocody, Abidjan',
        Icon: LocationIcon,
        id: '3'
    },
  
]

export default ContactInfoCards