import React, { useEffect } from 'react';
import banner from "images/banner.png";
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Heading from 'components/Heading/Heading';

const Aboutus = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <div className='container min-h-screen p-8'>
            <Heading
                className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
                isCenter
                desc=""
            >
                A propos de NOUS!
                <p className="text-lg text-gray-600 mt-2">En savoir plus sur notre mission et vision</p>
            </Heading>

            <div className="">
                <div className='p-8 md:p-16 font-poppins text-lg'>
                 Factoconn est un espace dédié aux entreprises du domaine industriel pour leur permettre de se connecter. Factoconn vient aider les professionnels du monde industriel (responsable maintenance, production, directeur achat, ....) à résoudre leurs problèmes quotidiens.
                </div>
                <div className="flex flex-col space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
                    <div className="border p-6 rounded-lg bg-white shadow space-y-4 hover:shadow-lg transform hover:scale-105 transition-transform duration-300">
                        <div className="border-b pb-2">
                            <h2 className="text-xl font-bold">Notre Mission</h2>
                        </div>
                        <div>
                            <p>Chez Factoconn, nous connectons les professionnels du secteur industriel pour résoudre leurs défis quotidiens en leur offrant un accès simplifié aux pièces de rechange, consommables, équipements, formations, et prestataires de services essentiels. Nous contribuons à la réduction des pertes de production et à l'optimisation des stocks, tout en favorisant l'innovation et la pérennité des entreprises industrielles.</p>
                        </div>
                        <div>
                            <p></p>
                        </div>
                    </div>

                    <div className="border p-6 rounded-lg bg-white shadow space-y-4 hover:shadow-lg transform hover:scale-105 transition-transform duration-300">
                        <div className="border-b pb-2">
                            <h2 className="text-xl font-bold">Notre Vision</h2>
                        </div>
                        <div>
                            <p>Devenir la plateforme industrielle de référence, facilitant l'accès aux ressources, services et solutions indispensables pour optimiser la performance des entreprises industrielles à travers le monde.</p>
                        </div>
                        <div>
                            <p></p>
                        </div>
                    </div>

    
                </div>
                <div className="flex flex-row justify-center items-center text-center mt-12 mb-12 lg:mb-14 ">
                        <a href='/contactus'>
                        <ButtonPrimary type="button" className='lg:w-[335px] w-[200px] lg:h-[60px] h-[46px] box-border border-2 border-none'>
                            <span className="font-gotu font-normal lg:text-[22px] text-[16px] text-white leading-[28px] tracking-[0.02em]">Nous Contacter</span>
                            <span className="text-white ml-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.4301 18.819C14.3316 18.8194 14.2339 18.8001 14.1429 18.7623C14.0519 18.7245 13.9694 18.669 13.9001 18.599C13.7606 18.4579 13.6824 18.2674 13.6824 18.069C13.6824 17.8706 13.7606 17.6801 13.9001 17.539L19.4401 11.999L13.9001 6.45901C13.7703 6.31636 13.7005 6.12921 13.7051 5.93642C13.7097 5.74364 13.7884 5.56003 13.9248 5.42374C14.0612 5.28744 14.2449 5.20893 14.4377 5.20451C14.6305 5.20009 14.8175 5.27011 14.9601 5.40001L21.0301 11.47C21.1696 11.6111 21.2478 11.8016 21.2478 12C21.2478 12.1984 21.1696 12.3889 21.0301 12.53L14.9601 18.6C14.8907 18.6698 14.8081 18.7252 14.7171 18.7628C14.6261 18.8004 14.5285 18.8195 14.4301 18.819Z" fill="#fff" />
                                    <path d="M20.33 12.75H3.5C3.30149 12.7487 3.11149 12.6693 2.97112 12.5289C2.83075 12.3885 2.75131 12.1985 2.75 12C2.75131 11.8015 2.83075 11.6115 2.97112 11.4711C3.11149 11.3307 3.30149 11.2513 3.5 11.25H20.33C20.5285 11.2513 20.7185 11.3307 20.8589 11.4711C20.9993 11.6115 21.0787 11.8015 21.08 12C21.0787 12.1985 20.9993 12.3885 20.8589 12.5289C20.7185 12.6693 20.5285 12.7487 20.33 12.75Z" fill="#fff" />
                                </svg>
                            </span>
                        </ButtonPrimary>
                        </a>
                    </div>
            </div>

            {/* <a href='/contactus' ><ButtonPrimary type="button" className='lg:w-[235px] w-[200px] lg:h-[60px] h-[46px] box-border border-2 border-[#423930]'>
                        <span className="font-gotu font-normal lg:text-[22px] text-[16px] text-white leading-[28px] tracking-[0.02em]">Contact Now</span>
                        <span className="text-white ml-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.4301 18.819C14.3316 18.8194 14.2339 18.8001 14.1429 18.7623C14.0519 18.7245 13.9694 18.669 13.9001 18.599C13.7606 18.4579 13.6824 18.2674 13.6824 18.069C13.6824 17.8706 13.7606 17.6801 13.9001 17.539L19.4401 11.999L13.9001 6.45901C13.7703 6.31636 13.7005 6.12921 13.7051 5.93642C13.7097 5.74364 13.7884 5.56003 13.9248 5.42374C14.0612 5.28744 14.2449 5.20893 14.4377 5.20451C14.6305 5.20009 14.8175 5.27011 14.9601 5.40001L21.0301 11.47C21.1696 11.6111 21.2478 11.8016 21.2478 12C21.2478 12.1984 21.1696 12.3889 21.0301 12.53L14.9601 18.6C14.8907 18.6698 14.8081 18.7252 14.7171 18.7628C14.6261 18.8004 14.5285 18.8195 14.4301 18.819Z" fill="#423930" />
                                <path d="M20.33 12.75H3.5C3.30149 12.7487 3.11149 12.6693 2.97112 12.5289C2.83075 12.3885 2.75131 12.1985 2.75 12C2.75131 11.8015 2.83075 11.6115 2.97112 11.4711C3.11149 11.3307 3.30149 11.2513 3.5 11.25H20.33C20.5285 11.2513 20.7185 11.3307 20.8589 11.4711C20.9993 11.6115 21.0787 11.8015 21.08 12C21.0787 12.1985 20.9993 12.3885 20.8589 12.5289C20.7185 12.6693 20.5285 12.7487 20.33 12.75Z" fill="#423930" />
                            </svg>
                        </span>
                    </ButtonPrimary></a> */}
        </div>
    )
};

export default Aboutus;