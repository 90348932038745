import TYPES from "../types";

const initialState = {
  machinerypartsdata: '',
};

export const MachineryPartsReducer = (state = initialState, action: { type: any; payload: { data: any; }; }) => {
  switch (action.type) {
    case TYPES.MACHINERY_PARTS_DATA:
      return { ...state, machinerypartsdata: action.payload };
    case TYPES.MACHINERY_PARTS_CAT:
      return { ...state, machinerypartscat: action.payload };
    default:
      return state;
  }
};