import React, { FC } from "react";
import { Helmet } from "react-helmet-async";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard";
import { PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import { useSelector } from "react-redux";
import NotFound from "./NotFound/NotFound";

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const activecategory = useSelector((state: { ActiveCategoryReducer: any; }) => state.ActiveCategoryReducer.activecategory);
  const activesubcategory = useSelector((state: { ActiveCategoryReducer: any; }) => state.ActiveCategoryReducer.activesubcategory);

  const FILTERED_PRODUCTS : any = activecategory == null ? PRODUCTS : PRODUCTS?.filter((product: any) => product?.category == activecategory?.catname);
  const FILTERED_PRODUCTS_BY_SUBCAT : any = ( activesubcategory && activesubcategory?.length == 0) ? FILTERED_PRODUCTS : FILTERED_PRODUCTS?.filter((product: any) => product?.tags?.some((cat: any) => activesubcategory?.includes(cat)));

  // console.log({activecategory, PRODUCTS, activesubcategory, FILTERED_PRODUCTS, FILTERED_PRODUCTS_BY_SUBCAT });

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Factoconn Products</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              {activecategory ? activecategory?.name : "Portefeuille de produits"}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              Factoconn propose une large gamme de produits industriels dédiés à la maintenance et à l’optimisation des équipements.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <SidebarFilters />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10 ">
                  {FILTERED_PRODUCTS_BY_SUBCAT.map((item: any, index: number) => (
                    <ProductCard data={item} key={index} />
                  ))}
                </div>

                {
                        (FILTERED_PRODUCTS_BY_SUBCAT == null || FILTERED_PRODUCTS_BY_SUBCAT == '' || FILTERED_PRODUCTS_BY_SUBCAT?.length === 0) &&
                        <div className="my-0 h-[420px] mt-4">
                            <NotFound
                                innerWrapperClassNames="h-[420px]"
                                innerText="Aucune donnee trouvée pour ce critère."
                            />
                        </div>

                    }
              </div>
            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* <SectionSliderCollections /> */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection2;
