import React, { FC, useState } from 'react';
import { ServicesProps } from './Service';
import reservation from '../../assets/services/reservation.png';
import { Link } from 'react-router-dom';


const ServiceTranslate: FC<ServicesProps> = ({ service }) => {
    const [readMore, setReadMore] = useState(false);

    return (
        <div className='flex flex-col-reverse lg:flex-row w-full' >
            <div className={`w-full lg:p-4 ${service.cardHeight} bg-[#FFFFFF] rounded-br-[30px] z-40`}>
                <div className='flex flex-col space-y-[30] lg:space-y-[0] lg:space-x-[100px]'>
                    <div className='mt-[30px] lg:mt-[51px]'>
                        <img src={service.icon} className={'w-[30px] lg:w-auto'} />
                    </div>
                    <div className='flex flex-col space-y-[20px] !mt-[20px] lg:!-mt-[51px]'>
                        <div className='font-poppins font-bold lg:font-semibold text-2xl lg:text-[28px] text-[#0D2C3B] leading-[44px] tracking-[-0.07em]'>
                            {service.name}
                        </div>
                        <div className={`font-poppins w-full font-normal text-base lg:text-[18px] text-[#000000] tracking-[-0.03em] leading-[26px] ${!readMore && "line-clamp-6"}`}>
                            { service.description }
                            {/* <div dangerouslySetInnerHTML={{ __html: service.content.text }} /> */}
                        </div>
                        {/* <Link to={`/services/${service.id}`} >
                            <button className='font-medium text-[#0D2C3B] self-start lg:inline-block underline cursor-pointer' onClick={() => setReadMore(!readMore)}>
                                {"Plus d'information"}
                            </button>
                        </Link> */}
                        <div className='font-poppins font-semibold text-sm text-[16px] text-[#0D2C3B] opacity-50 leading-[24px]'>
                            {service.subdescription}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full lg:h-[620px] lg:-ml-[240px]'>
                <img className='object-fit rounded-[20px]' src={service.image} />
            </div>
        </div>
    )
};


export default ServiceTranslate;