import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from './store';
import { algoliaUpdate } from "infras/algolia";
// import history from './store/history';
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize('G-4WPB2594MC');

  useEffect(() => {
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname 
    });
  }, []);


  try {
    // --unhandled-rejections=mode
    algoliaUpdate();
  } catch (error) {

  }
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>Factoconn</title>
        <meta
          name="description"
          content="Factoconn"
        />
      </Helmet>

      {/* MAIN APP */}
      <Provider store={store}>
        <div className="bg-white text-base dark:bg-slate-900 text-slate-900 dark:text-slate-200">
          <MyRouter />
        </div>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
