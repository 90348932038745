// import {
//     accomodation,
//     accomodationIco,
//     events,
//     eventsIco,
//     leisuretime,
//     leisuretimeIco,
//     medicaltravel,
//     medicaltravelIco,
//     reservation,
//     reservationIco,
//     transportation,
//     transportationIco
// } from "../../assets";

import service1 from '../../images/service1.png';
import service2 from '../../images/service2.png';
import service3 from '../../images/service3.png';
import service4 from '../../images/service4.png';
// import flight from '../../assets/services/flight.png';
// import residence from '../../assets/services/residence.jpg';
// import restauration from '../../assets/services/restauration.png';

// import hotelsv1 from '../../assets/services/hotelsv1.png';
// import hotelappart from '../../assets/services/hotelappart.png';
// import transferv2 from '../../assets/services/transferv2.png';
// import restaurationv1 from '../../assets/services/restaurationv1.png';
// import toursv1 from '../../assets/services/toursv1.png';
// import realestatev1 from '../../assets/services/realestatev1.png';




export const ServicesData: any = [
    {
        id: 'logistics',
        name: 'Global Logistics & Shipping',
        description: `We handle the entire shipping process from start to finish, ensuring timely and secure delivery of all goods worldwide. Our team manages all aspects of customs clearance, documentation, and shipping routes to meet your timelines.`,
        subdescription: 'Logistics, Shipping',
        // icon: reservationIco,
        image: service1,
        cardHeight: 'lg:h-[338px]',
    },
    {
        id: 'product',
        name: 'Product Customization',
        description: 'We offer tailor-made solutions for your product needs. Whether it’s adjusting the packaging, size, or branding of our soap products, we ensure customization to your specifications for a perfect fit in your market.',
        subdescription: 'Customization',
        // icon: accomodationIco,
        image: service2,
        cardHeight: 'lg:h-[432px]',
    },
    {
        id: 'quality',
        name: 'Quality Control & Certification',
        description: 'ZKO ensures that all products meet international quality standards. We work closely with certification bodies and regularly conduct quality checks to guarantee premium products for our clients.',
        subdescription: 'Quality Control',
        // icon: medicaltravelIco,
        image: service3,
        cardHeight: 'lg:h-[391px]',
    },
    {
        id: 'order',
        name: 'Bulk Order Management',
        description: 'With the ability to manage large-scale orders, we offer competitive pricing for bulk purchases. Our team handles everything from production to delivery, ensuring a seamless supply chain experience.',
        subdescription: 'Bulk, Order',
        // icon: leisuretimeIco,
        image: service4,
        cardHeight: 'lg:h-[410px]'
    }
];