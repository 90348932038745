import TYPES from "../types";

const initialState = {
    activesidebaritem: '',
};

export const SidebarReducer = (state = initialState, action: { type: any; payload: { data: any; }; }) => {
  switch (action.type) {
    case TYPES.ACTIVE_SIDEBAR_ITEM:
        console.log({action});
      return {...state, activesidebaritem: action.payload};
    default:
      return state;
  }
};
